<template>
  <v-container class="pt-7 pb-11">
    <v-progress-linear v-if="getLoading" height="2" indeterminate/>
    <template v-else>
      <v-row class="mt-0 mb-4">
        <v-col>
          <v-breadcrumbs :items="breadcrumbsItems" divider="/" class="pa-0"/>
        </v-col>
      </v-row>
      <v-row class="mb-8" justify="space-between" align="center">
        <v-col cols="auto" class="text-left">
          <h1 class="text-h4">{{ 'createCustomerMargin'|localize }}</h1>
        </v-col>
      </v-row>
      <div class="white elevation-2 rounded">
        <v-container class="px-4">
          <v-form ref="marginForm" lazy-validation>
            <v-row class="mb-2" align="start" justify="space-between">
              <v-col cols="6">
                <h6 class="text-subtitle-1 mb-2">{{ 'customerId' | localize }} *</h6>
                <v-text-field
                  v-model.number="customer_id"
                  dense
                  outlined
                  maxlength="10"
                  :loading="getUserNameLoading"
                  :disabled="getUserNameLoading"
                  :placeholder="'customerId' | localize"
                  :rules="[validationRules.requiredNumber]"
                  @input="customer_name = ''"
                  @blur="getWpUserName"
                  persistent-hint
                  :hint="'maxValue10Digits'|localize"
                />
              </v-col>
              <v-col cols="6">
                <h6 class="text-subtitle-1 mb-4">{{ 'customer' | localize }} *</h6>
                <div>{{ customer_name ? customer_name : '--' }}</div>
              </v-col>
            </v-row>
            <v-row class="mb-2" align="start" justify="space-between">
              <v-col cols="6">
                <h6 class="text-subtitle-1 mb-2">{{ 'transportMode' | localize }}</h6>
                <v-select
                  v-model="transport_mode"
                  :items="computedModeList"
                  item-text="name"
                  item-value="value"
                  dense
                  outlined
                  :placeholder="'transportMode'|localize"
                >
                  <template v-slot:item="{ item, on }">
                    <div v-if="item['value']" class="d-flex">
                      <v-icon class="mr-2" color="silver-chalice">{{ TRANSPORT_ICONS[item['value']] }}</v-icon>
                      <v-list-item-title>{{ item.value|localize }}</v-list-item-title>
                    </div>
                    <div v-else>{{ item['name'] }}</div>
                  </template>
                  <template v-slot:selection="{ item, index }">
                    <div v-if="item['value']" class="d-flex">
                      <v-icon class="mr-2" color="silver-chalice">{{ TRANSPORT_ICONS[item['value']] }}</v-icon>
                      <v-list-item-title>{{ item.value|localize }}</v-list-item-title>
                    </div>
                    <div v-else>{{ item['name'] }}</div>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row class="mb-2" align="start" justify="space-between">
              <v-col cols="6">
                <h6 class="text-subtitle-1 mb-2">{{ 'from' | localize }}</h6>
                <v-autocomplete
                  v-model="from_country_code"
                  :items="ComputedCountryList"
                  :item-text="item => item.code ? `${item.name} (${item.code})` : item.name"
                  item-value="code"
                  dense
                  outlined
                  :placeholder="'selectCountry'|localize"
                />
              </v-col>
              <v-col cols="6">
                <h6 class="text-subtitle-1 mb-2">{{ 'to' | localize }}</h6>
                <v-autocomplete
                  v-model="to_country_code"
                  :items="ComputedCountryList"
                  :item-text="item => item.code ? `${item.name} (${item.code})` : item.name"
                  item-value="code"
                  dense
                  outlined
                  :placeholder="'selectCountry'|localize"
                />
              </v-col>
            </v-row>
            <v-row class="mb-2" align="start" justify="space-between">
              <v-col cols="6">
                <h6 class="text-subtitle-1 mb-2">{{ 'transportCompany' | localize }}</h6>
                <v-autocomplete
                  v-model="transport_company"
                  :items="computedCompanies"
                  item-text="name"
                  item-value="id"
                  dense
                  outlined
                  :loading="getCompaniesLoading"
                  :disabled="getCompaniesLoading"
                  :placeholder="'company'|localize"
                />
              </v-col>
              <v-col cols="6">
                <h6 class="text-subtitle-1 mb-2">{{ 'margin' | localize }}, % *</h6>
                <v-text-field
                  v-model="margin"
                  dense
                  outlined
                  maxlength="4"
                  :placeholder="'margin' | localize"
                  :rules="[marginRangeRule(), validationRules.requiredNumber]"
                  persistent-hint
                  :hint="'availbleRange1000'|localize"
                />
              </v-col>
            </v-row>
          </v-form>
          <v-row justify="space-between">
            <v-col cols="auto">
              <v-btn
                v-if="margin_id"
                rounded
                depressed
                color="error"
                :loading="deleteLoading"
                :disabled="saveLoading || deleteLoading"
                @click="$refs.deleteDialog.open()"
              >
                {{ 'delete'|localize }}
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn
                depressed
                rounded
                color="primary"
                :loading="saveLoading"
                :disabled="deleteLoading || saveLoading || !customer_name"
                @click="saveMargin"
              >
                {{ 'save'|localize }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </template>
    <SimpleDialog
      ref="deleteDialog"
      :title="'deleteMargin'|localize"
      :text="'deleteMarginText'|localize"
      :accept-text="'delete'|localize"
      :decline-text="'back'|localize"
      @accept="deleteMargin"
    />
  </v-container>
</template>

<script>
  import validationRules from '@/helpers/validationRules';
  import localize from '@/filters/localize';
  import api from '@/api/api';
  import methods from '@/helpers/methods';
  import {TRANSPORT_TYPES, TRANSPORT_ICONS} from '@/enums/transportMode';
  import {COUNTRY_LIST} from '@/enums/countryList';
  import SimpleDialog from '@/components/common/SimpleDialog';

  export default {
    name: "CreateThirdLevel",
    components: {SimpleDialog},

    data() {
      return {
        COUNTRY_LIST,
        TRANSPORT_TYPES,
        TRANSPORT_ICONS,
        validationRules: validationRules,
        margin_id: this.$router.currentRoute.query.margin_id,
        customer_id: '',
        customer_name: '',
        transport_mode: null,
        from_country: '',
        from_country_code: null,
        to_country: '',
        to_country_code: null,
        transport_company: null,
        companies: [],
        margin: '',
        getLoading: false,
        getUserNameLoading: false,
        getCompaniesLoading: false,
        saveLoading: false,
        deleteLoading: false
      };
    },

    async mounted() {
      if (this.margin_id) {
        try {
          this.getLoading = true;
          let companiesData = await api.getSingleThirdLevelMargin(this.margin_id);
          this.setMarginData(companiesData.data);
          this.getLoading = false;
        } catch (e) {
          this.getLoading = true;
        }
      }
      try {
        this.getCompaniesLoading = true;
        let companiesData = await api.getCompanyList();
        this.companies = companiesData.data;
        this.getCompaniesLoading = false;
      } catch (e) {
        this.getCompaniesLoading = true;
      }
    },

    watch: {
      'from_country_code'(newValue) {
        newValue ? (this.from_country = COUNTRY_LIST.filter(countryData => countryData.code === newValue)[0].name) : this.from_country = '';
      },
      'to_country_code'(newValue) {
        newValue ? (this.to_country = COUNTRY_LIST.filter(countryData => countryData.code === newValue)[0].name) : this.to_country = '';
      },
    },

    computed: {
      breadcrumbsItems() {
        return [
          {
            text: localize('marginSettings'),
            disabled: false,
            to: {name: 'price-settings'}
          },
          {
            text: localize('createCustomerMargin'),
            disabled: true,
          },
        ];
      },
      ComputedCountryList() {
        return [
          {
            "code": null,
            "name": localize('anyCountry')
          },
          ...COUNTRY_LIST
        ];
      },
      computedCompanies() {
        return [
          {
            id: null,
            name: localize('anyCompany')
          },
          ...this.companies
        ]
      },
      computedModeList() {
        return [
          {
            name: localize('allTransportModes'),
            value: null
          },
          ...this.$store.getters.transportModeList
        ];
      },
    },

    methods: {
      async getWpUserName() {
        try {
          this.getUserNameLoading = true;
          let wpData = await api.getWpUserName(this.customer_id);
          this.customer_name = wpData.data.name;
          this.getUserNameLoading = false;
        } catch (e) {
          this.getUserNameLoading = false;
        }
      },
      async deleteMargin() {
        try {
          this.deleteLoading = true;
          await api.deleteThirdLevelMargin(this.margin_id);
          methods.showGlobalSnackbar('success', localize('done'));
          this.$router.push({name: 'price-settings'});
        } catch (e) {
          this.deleteLoading = false;
          methods.showGlobalSnackbar('error', methods.getErrors(e.response));
        }
      },
      setMarginData(data) {
        this.customer_id = data.customer_id;
        this.customer_name = data.customer_name;
        this.margin = data.margin;
        data.transport_company && (this.transport_company = data.transport_company);
        data.transport_mode && (this.transport_mode = data.transport_mode);
        if (data.from_country_code) {
          this.from_country_code = data.from_country_code;
          this.from_country = data.from_country;
        }
        if (data.to_country_code) {
          this.to_country_code = data.to_country_code;
          this.to_country = data.to_country;
        }
      },
      queryData() {
        let queryData = {};
        queryData.customer_id = this.customer_id;
        queryData.customer_name = this.customer_name;
        queryData.margin = this.margin;
        this.margin_id && (queryData.id = this.margin_id);
        this.transport_mode && (queryData.transport_mode = this.transport_mode);
        this.transport_company && (queryData.transport_company = this.transport_company);
        if (this.from_country_code) {
          queryData.from_country_code = this.from_country_code;
          queryData.from_country = this.from_country;
        }
        if (this.to_country_code) {
          queryData.to_country_code = this.to_country_code;
          queryData.to_country = this.to_country;
        }
        return queryData;
      },
      async saveMargin() {
        if (this.$refs.marginForm.validate()) {
          try {
            this.saveLoading = true;
            if (this.margin_id) {
              await api.updateThirdLevelMargin(this.queryData());
            } else {
              await api.saveThirdLevelMargin(this.queryData());
            }
            methods.showGlobalSnackbar('success', localize('done'));
            this.$router.push({name: 'price-settings'});
          } catch (e) {
            this.saveLoading = false;
            methods.showGlobalSnackbar('error', methods.getErrors(e.response));
          }
        }
      },
      marginRangeRule() {
        return v => !v || (v < 1000 && v > -1000) || localize('availbleRange1000Error');
      }
    }
  };
</script>
